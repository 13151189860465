<template>

    <v-sheet
        :style="` ${!inline ? ('border: 1px solid ' + wsACCENT + ';') : ''} color: ${wsACCENT}`"
        :color="!inline ? wsLIGHTCARD : 'transparent'"
        class="wsRoundedLight pa-4 my-4" >


      <div class="d-flex justify-space-between">
        <div v-if="isValid">
          <h5 v-if="isYoutube" :style="`color: ${wsACCENT}`">
            <v-icon class="mr-2" :color="wsACCENT">mdi-youtube</v-icon>
            Youtube {{ $t('Video') }}
          </h5>
          <h5 v-if="isVimeo" :style="`color: ${wsACCENT}`">
            <v-icon class="mr-2" :color="wsACCENT">mdi-vimeo</v-icon>
            Vimeo {{ $t('Video') }}
          </h5>
          <h5 v-if="isCoub" :style="`color: ${wsACCENT}`">
            <v-icon class="mr-2" :color="wsACCENT">mdi-coub</v-icon>
            Coub {{ $t('Video') }}
          </h5>
          <h5 v-if="isDailymotion" :style="`color: ${wsACCENT}`">
            <v-icon class="mr-2" :color="wsACCENT">mdi-dailymotion</v-icon>
            Dailymotion {{ $t('Video') }}
          </h5>
        </div>
        <div v-else>
          <h5 v-if="video" :style="`color: ${wsACCENT}`">
            <v-icon class="mr-2" :color="wsACCENT">mdi-link-variant-off</v-icon>
            {{ $t('InsertEmbedVideoLink') }}
          </h5>
        </div>
        <v-btn v-if="closable" icon @click="$emit('cancel')"><v-icon>mdi-close</v-icon></v-btn>
      </div>


      <div class="d-flex my-4">
        <v-text-field
            @input="handleUrlChange"
            v-model="url"
            :error="!isValid && url.length > 0"
            :placeholder="$t('Link')"
            :color="wsACCENT"
            :label="$t('Link')"
            background-color="white"
            prepend-inner-icon="mdi-link"
            hide-details
            class="wsInput mr-4"
            dense
            outlined
            clearable
        />
        <v-btn v-if="!noButton" height="40" @click="save" :disabled="!isValid" :outlined="!isValid"  :dark="isValid" :color="wsATTENTION" class="noCaps"> {{ $t('Save') }}</v-btn>
      </div>

      <v-expand-transition>
        <v-sheet class="wsRoundedLight" v-if="isValid" dark>
          <iframe
              :src="EMBED_URL"
              :style="embedStyle"
              style="border : none"
              loading="lazy"
              sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation"
              class="embed-style wsRoundedLight"
              allowfullscreen ></iframe>
        </v-sheet>
      </v-expand-transition>


      <h5 class="mt-3 ">
        <v-icon class="mr-2" :color="wsACCENT">mdi-information-outline</v-icon>
        <span class="font-weight-medium mr-2">Підтримуються наступні сервіси:</span>
        <a :style="`color : ${wsACCENT}`" target="_blank" href="https://youtube.com">Youtube</a> ,
        <a :style="`color : ${wsACCENT}`" target="_blank" href="https://vimeo.com">Vimeo</a> ,
        <a :style="`color : ${wsACCENT}`" target="_blank" href="https://coub.com">Coub</a> ,
        <a :style="`color : ${wsACCENT}`" target="_blank" href="https://www.dailymotion.com/us">Dailymotion</a>
      </h5>

  </v-sheet>

</template>



<script>


import embedContent from "@/components/UI/embedVideo/mixin/embedContent";

export default {

  mixins : [embedContent],
  name : 'wsEmbedEditor',
  props: {
    value: {
      type: Object,
      default() { return {} }
    },
    video : {
      type : Boolean,
      default : false
    },
    closable : {
      type : Boolean,
      default : false
    },
    inline : {
      type : Boolean,
      default : false
    },
    noButton : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      url : '',
      height : '350',
      width : ''
    }
  },
  computed: {

    embedStyle() {

      return 'width : ' + (this.width ? (this.width + 'px') : '100% ;' ) +
          'height: ' + this.height + 'px;' +
          'border : 1px solid ' + this.wsACCENT

    }

  },
  watch : {
    isValid(value) {
      if ( !value ) {
        this.url = null
        this.$emit('input' , {})
      }
    },
    url(value) {
      this.PARSE_EMBED_SRC(value)
    }
  },
  methods : {
    handleUrlChange() {
      if ( !this.noButton ) {
        return
      }

      setTimeout(()=> {
        if ( this.isValid && this.url.length > 0) {
          this.save()
        }
      },50)

    },
    save() {
      if ( !this.isValid ) {
        return this.notify(this.$t('EnterValidLink'))
      }
      if ( this.video ) {
        let data = {
          url : this.url,
          height : this.height,
          type : this.EMBED_TYPE
        }
        this.$emit('input',data)
        this.$emit('change',data)
      }
    }
  },
  mounted() {
    if (this.value.url) {
      this.url = this.value.url
      this.height = this.value.height
      this.PARSE_EMBED_SRC(this.url)
    }
  }



}

</script>

<style>
.embed-style {

}
</style>